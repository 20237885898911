<template>
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
        <div class="col-span-1 flex items-center flex-wrap">
          <h2 class="text-2xl font-bold mr-3">Awaiting Approval Cash Transactions</h2>
        </div>
        <div class="col-span-1">
          <div class="relative">
            <search-form
              v-model="query"
              placeholder="Search for user's name, status, etc."
              @submit="(event) => $refs.table.loadAjaxData()"
            />
          </div>
        </div>
      </div>
      <div class="border border-solid border-blue-200">
        <datatable
          :index="true"
          :url="`${this.$baseurl}/admin/personal/cash/awaiting-approval`"
          :filters="transactions.filters"
          :ajax="true"
          :ajax-pagination="true"
          :on-click="goToUser"
          :fillable="true"
          :columns="transactions.columns"
          :actions="transactions.actions"
          ref="table"
        />
      </div>
    </div>
  </template>
  <script>
  import moment from 'moment';
  export default {
    data() {
      return {
        query: '',
        transactions: this.$options.resource([], {
          columns: [
            {
              name: 'wallet_id',
              th: 'User\'s Wallet'
            },
            {
              name: 'account_name',
              th: 'Account Name',
            },
            {
              name: 'metadata',
              th: 'Account Number',
              render: (_, metadata) => metadata['account_number']
            },
            {
              name: 'metadata',
              th: 'Bank Name',
              render: (_, metadata) => metadata['bank_code']
            },
            {
              name: 'amount',
              th: 'Amount Attempting To Transfer',
            },
            {
              name: 'obligor_threshold_status',
              th: 'Approval Status',
              render: (_, obligor_threshold_status) => this.cashTransactionAwaitingApprovalStatusCheck(obligor_threshold_status)
            },
            {
              name: 'created_at',
              th: 'Created At',
              render: (_, created_at) =>
                moment(created_at).format("MMM Do YYYY, h:mm:ss a")
            }
          ],
          actions: [
              {
                text: 'approve cash transfer',
                class: 'bg-green-600 text-white rounded-sm px-4 py-2 hover:bg-green-500',
                action: this.approveCashTransfer,
                show: () => this.canDo('credpal:can_ratify_awaiting_cash_transfers')
              },
              {
                text: 'mark cash transfer success',
                class: 'bg-blue-500 text-white rounded-sm px-4 py-2 hover:bg-blue-400 my-1',
                action: this.markSuccessCashTransfer,
                show: () => this.canDo('credpal:can_ratify_awaiting_cash_transfers')
              },
              {
                text: 'reject cash transfer',
                class: 'bg-red-600 text-white rounded-sm px-4 py-2 hover:bg-red-500',
                action: this.rejectCashTransfer,
                show: () => this.canDo('credpal:can_ratify_awaiting_cash_transfers')
              }
          ],
          filters: [
            {title: 'Pending', name: 'pending'},
            {title: 'Approved', name: 'approved'},
            {title: 'Rejected', name: 'rejected'}
          ]
        }),
      };
    },
    methods: {
      reloadTable() {
        this.$refs.table.loadAjaxData();
      },
      approveCashTransfer(cashTransfer) {
        this.sendRequest('admin.users.cash.approveAwaitingCashTransfer', cashTransfer?.id, {
          success: () => { 
            this.$success({
              title: 'Operation Successful',
              body: 'Transfer Approved',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: () => {
            this.$error({
              title: 'Operation Failed',
              body: 'Transfer Approval Fail',
              button: 'Okay',
            });
          },
        });
      },
      rejectCashTransfer(cashTransfer) {
        this.sendRequest('admin.users.cash.rejectAwaitingCashTransfer', cashTransfer?.id, {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Transfer Rejection',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: () => {
            this.$error({
              title: 'Operation Failed',
              body: 'Transfer Rejection Fail',
              button: 'Okay',
            });
          },
        });
      },
      markSuccessCashTransfer(cashTransfer) {
        this.sendRequest('admin.users.cash.markSuccessAwaitingCashTransfer', cashTransfer?.id, {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Transfer Rejection',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: () => {
            this.$error({
              title: 'Operation Failed',
              body: 'Transfer Rejection Fail',
              button: 'Okay',
            });
          },
        });
      },
      goToUser({ user_id, user }) {
        return this.$router.push({
          name: 'staff-view',
          params: {
            staffId: user_id?.id || user?.id,
          },
        });
      },
      cashTransactionAwaitingApprovalStatusCheck(status) {
        switch (status) {
          case 'rejected':
            return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-2 py-1 inline-block">
                ${status}
              </div>`;
          case 'pending':
            return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                ${status}
              </div>`;
          case 'approved':
          default:
            return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
                ${status}
              </div>`;
        }
      }
    },
  };
  </script>
  